<template>
  <div>
    <div v-if="loaded">
      <div v-if="!error">
        <h1 class="auth-layout__title">{{ $t('login.email_verified') }}</h1>
        <div class="auth-layout__text">{{ $t('login.mobile_text') }}</div>
      </div>
      <div v-else>
        <h1 class="auth-layout__title">{{ $t('login.already_verified') }}</h1>
        <div class="auth-layout__text">{{ $t('login.mobile_text') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {verifyEmail} from '@/api/auth';

export default {
  name: 'EmailVerified',
  data() {
    return {
      error: false,
      loaded: false
    };
  },
  mounted() {
    verifyEmail(this.$route.fullPath).then(() => {
      this.loaded = true;
    }).catch(() => {
      this.loaded = true;
      this.error = true;
    });
  }
};
</script>
